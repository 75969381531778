@charset "utf-8";
@import 'import/var';
@import 'import/animation';

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/

body {
	font-size: $pc_default_font_size;
	line-height: $pc_default_line_height;
}

@mixin container($width: $pc_container_width) {
	width: 100%;
	box-sizing: border-box;
	padding-left: $pc_padding;
	padding-right: $pc_padding;
	margin-left: auto;
	margin-right: auto;
	max-width: $width;
}

.pc_container,
.ipc_container,
.pc_tb_container,
.container {
	@include container;
	&._relative {
		position: relative;
		top: 0;
		left: 0;
	}
	&._lg {
		@include container(1460px);
	}
	&._sm {
		@include container(600px);
	}
	&._sm2 {
		@include container(300px);
	}
}

.pc_padding,
.padding {
	padding-left: $pc_padding;
	padding-right: $pc_padding;
}

.root {
	//padding-top: $pc_header_height;
}

.main_content {
	font-size: $pc_default_font_size;
	//padding-bottom: 80px;
}


/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

*/
.pc_none,
.root .pc_none {
	display: none;
}
.block,
.pc_block,
.ipc_block {
	display: block;
}

@media screen and (min-width: 1200px), print {
	.lg_block {
		display: block;
	}
}


@media screen and (min-width: 1000px) and (max-width: 1099px), print {
	.pc_s_block {
		display: block;
	}
}
.tel_link,
.tel_link-no {
	pointer-events: none;
	cursor: default;
	color: inherit;
	.root &,
	.root &:hover {
		text-decoration: none;
		color: inherit;
	}
}
.sp_click,
.pc_no_click {
	pointer-events: none;
	cursor: default;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************



## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/


/**************************

## - 段組設定 2列 3列 4列

PCの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .pc_column2**

	<ul class="pc_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .pc_column3**

	<ul class="pc_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .pc_column4**

	<ul class="pc_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/

.pc_column2 {
	@include columns(2, 40px);
}
.pc_column3 {
	@include columns(3, 20px);
}
.pc_column4 {
	@include columns(4, 20px);
}


.pc_column2b {
	@include columnsb(2, 30px);
}
.pc_column3b {
	@include columnsb(3, 20px);
}
.pc_column4b {
	@include columnsb(4, 20px);
}


.set {
	display: flex;
	justify-content: space-between;

	width: 100%;

	> * {
		flex-grow: 5;
	}

	> .left {
		order: 1;

	}
	> .right {
		order: 2;
	}
	> .fix {
		flex-shrink: 0;
		flex-grow: 0;
		width: 100%;
	}
	> .flex {
		display: flex;
	}
}

.footer {
	&__2 {
		position: relative;
		top: 0;
		left: 0;
	}
	&-scroll_to_top_stop {
		position: relative;
		
		.scroll_to_top {
			position: absolute;
			top: calc(-1 * #{$scroll_to_top_height});
			bottom: auto;
			
		}
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/
body {
	&.menu_open {
		overflow: hidden;
	}
}

.pc_header_menu_area {
	width: 100vw;
	height: 0;
	opacity: 0;
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	transition: 0.5s opacity;
	&._open {
		height: 100vh;
		opacity: 1;
		overflow-y: scroll;
		transition: 0.5s opacity;
	}
	&__inner {
		min-height: 930px;
		height: 100vh;
	}
//	.menu_area {
//		background: url(/common/img/bg01.jpg) no-repeat top center / cover;
//		padding-top: 50px;
//		padding-bottom: 50px;
//		position: relative;
//		box-sizing: border-box;
//		height: 60vh;
//		min-height: 560px;
//		&::before {
//			content: "";
//			display: block;
//			width: 310px;
//			height: 305px;
//			background: url(/common/img/header/menu_bg_logo.png)no-repeat center center / cover;
//			position: absolute;
//			right: 0;
//			top: 122px;
//		}
//		@media screen and (max-width: 1500px) {
//			> .container {
//				max-width: calc(100% - 150px);
//				margin-left: 150px;
//			}
//		}
//		.gnav_panel {
//			.toggle_btn {
//				display: none;
//			}
//			display: flex;
//			a {
//				color: #fff;
//			}
//			.gnav_area {
//				width: 240px;
//				.gnav {
//					> ul {
//						> li {
//							position: relative;
//							&::before {
//								content: "";
//								display: block;
//								width: 3px;
//								height: 17px;
//								background-color: #d6c6b4;
//								position: absolute;
//								top: 50%;
//								left: 0;
//								margin-top: -8px;
//							}
//							&:not(:last-of-type) {
//								border-bottom: 1px solid #753c3a;
//							}
//							.js-open_gnav_sub {
//								display: block;
//								padding: 19px 5px 20px 20px;
//								font-family: 'Noto Serif JP', serif;
//								font-weight: 600;
//								font-size: 18px;
//								box-sizing: border-box;
//								transition: 0.3s;
//								&._menu_hover,
//								&._hover,
//								&:hover {
//									background-color: rgba(208, 190, 171, 0.1);
//								}
//							}
//						}
//					}
//				}
//			}
//			.sub_menu_area {
//				background-color: rgba(208, 190, 171, 0.1);
//				width: calc(100% - 240px);
//				box-sizing: border-box;
//				padding: 15px 65px 27px;
//				position: relative;
//				.js-slider-gnav,
//				.slick-list,
//				.slick-track {
//					min-height: 100%!important;
//					height: 100%!important;
//				}
//				.gnav_sub_area {
//					height: 100%;
//					box-sizing: border-box;
//					&._show {
//						height: auto;
//						display: block;
//						animation-name: fade-in;
//						animation-duration: .3s;
//						animation-timing-function: $easeOutCubic;
//						animation-fill-mode: forwards;
//					}
//					&._leave {
//						height: auto;
//						display: block;
//						animation-name: fade-out;
//						animation-duration: .2s;
//						animation-timing-function: $easeOutCubic;
//						animation-fill-mode: forwards;
//					}
//				}
//				.gnav_sub {
//					position: relative;
//					height: 100%;
//					.title {
//						.text {
//							@include notosans;
//							color: #fff;
//							font-size: 28px;
//							font-weight: 300;
//							position: relative;
//							padding-left: 70px;
//							letter-spacing: 0.08em;
//							border-bottom: 1px solid #a49688;
//							padding-bottom: 5px;
//							margin-bottom: 30px;
//							&::before {
//								width: 64px;
//								height: 34px;
//								content: "";
//								display: block;
//								background: url(/common/img/header/gnav_title_icon.png)no-repeat center center / cover;
//								position: absolute;
//								top: 11px;
//								left: 0;
//							}
//						}
//					}
//				}
//			}
//			.gnav_sub__list {
//				display: flex;
//				flex-wrap: wrap;
//				.li1 {
//					width: 33.33%;
//					padding-left: 2em;
//					box-sizing: border-box;
//					margin-bottom: 5px;
//					@media #{$lg1260} {
//						width: 50%;
//					}
//					.a1 {
//						position: relative;
//						padding: 5px 2px 5px 43px;
//						display: inline-block;
//						&::before {
//							width: 34px;
//							height: 6px;
//							content: "";
//							display: block;
//							background: url(/common/img/icon/arrow/arrow_white.png)no-repeat center center / cover;
//							position: absolute;
//							left: 0;
//							top: 18px;
//							//margin-top: -3px;
//							//opacity: 1;
//							//transform: translateX(0);
//							transition: 0.6s;
//						}
//						.gnav_text {
//							.inner {
//								background-image: linear-gradient(#d9c8c8, #d9c8c8);
//								background-size: 100% 1px;
//								background-position: 0 100%;
//								background-repeat: no-repeat;
//								padding-bottom: 0.5em;
//								transition: 0.2s;
//							}//

//						}
//						&:hover {
//							&::before {
//								transform: translateX(5px);
//							}
//							.gnav_text {
//								.inner {
//									background-image: linear-gradient(transparent, transparent);
//								}
//							}
//						}
//					}
//				}
//			}
//		}
//	
//		&__footer {
//			min-height: 380px;
//			background-color: #fff;
//			padding-top: 58px;
//			position: relative;
//			&::before {
//				content: "";
//				display: block;
//				width: 1px;
//				height: 72px;
//				background: linear-gradient(180deg, #fff 0%, #fff 50%, #770000 50%, #770000 100%);
//				position: absolute;
//				top: -36px;
//				left: 50%;
//			}
//			.wrapper {
//				display: flex;
//				margin-bottom: 73px;
//				> * {
//					width: 50%;
//				}
//				.right {
//					box-sizing: border-box;
//					padding-left: 10px;
//				}
//			}
//			.hosp_name {
//				width: 100%;
//				max-width: 590px;
//				img {
//					width: 100%;
//				}
//			}
//			.hosp_info {
//				@include mincho_yu;
//				font-weight: 500;
//				color: #4a0a09;
//				display: flex;
//				flex-wrap: wrap;
//				margin-bottom: 15px;//

//				.tel {
//					font-size: 25px;
//					position: relative;
//					margin-right: 40px;
//					letter-spacing: 0.075em;
//					@media #{$sp} {
//						font-size: 21px;
//					}
//					@media #{$isp} {
//						font-size: 18px;
//					}
//					&::before {
//						content: "";
//						display: inline-block;
//						width: 16px;
//						height: 22px;
//						background: url(/common/img/icon/tel.png)no-repeat center center / cover;
//						margin-right: 6px;
//					}
//				}
//				.address {
//					display: block;
//					font-size: 22px;
//					position: relative;
//					color: #4a0a09;
//					transition: 0.3s;
//					@media #{$sp} {
//						font-size: 18px;
//					}
//					@media #{$isp} {
//						font-size: 16px;
//					}
//					&::before {
//						content: "";
//						display: inline-block;
//						width: 16px;
//						height: 22px;
//						background: url(/common/img/icon/address.png)no-repeat center center / cover;
//						margin-right: 8px;
//						margin-bottom: -3px;
//						transition: 0.3s;
//					}
//					&:hover {
//						opacity: 0.9;
//						&::before {
//							animation: icon_fuwafuwa 1.5s infinite;
//						}
//					}
//				}
//			}
//			.footer_gnav {
//				a {
//					font-family: 'Noto Serif JP', serif;
//					font-weight: 600;
//				}
//				.ext {
//					&::after {
//						top: 3px;
//					}
//				}
//			}
//			.footer_sns {
//				align-items: center;
//				.title {
//					font-family: 'Noto Serif JP', serif;
//					font-weight: 600;
//					color: #4a0a09;
//					position: relative;
//					padding-right: 70px;
//					&::before {
//						content: "";
//						display: block;
//						width: 49px;
//						height: 1px;
//						background-color: #4a0a09;
//						position: absolute;
//						right: 11px;
//						top: 50%;
//					}
//				}
//			}
//		}
//	}
}
.header {
	&__inner {
		background-color: rgba(0, 0, 0, 0.4);
		transition: 0.2s background-color;
	}
	&-fixed {
		.header__inner {
			background-color: #770000;
		}
	}
	&__search_input_area {
		background-color: #770000;
		height: 64px;
		width: 100%;
		position: absolute;
		top: -64px;
		left: 0;

		.container {
			display: flex;
			justify-content: space-between;
			height: 64px;
			align-items: center;

			> * {
				min-width: 0;
				max-width: 100%;
				width: 100%;
			}

			> .left {
				.search {
					&__text {
						color: #fff;
						background-color: #5c0000;
						border: 0 none;
						padding: 0 10px 0 65px;
						&:focus {
							outline: 1px rgba(#fff, .2) solid;
						}
						::placeholder {
							color: #a77a7a;
						}

					}
					&__submit {
						right: auto;
						left: 0;
						border-left: 0 none;
						background-image: url('../img/icon/header_search_input.png');
						background-size: contain;
						width: 54px;
						height: 40px;
					}
				}
			}

			> .right {
				flex-basis: 64px;
				flex-shrink: 0;
				flex-grow: 0;

				a {
					display: block;
					width: 64px;
					height: 64px;
					position: relative;
					top: 0;
					left: 0;
					img {
						display: block;
						width: 25px;
						height: 25px;
						position: absolute;
						top: 50%;
						left: 50%;
						margin-top: -12px;
						margin-left: -12px;
					}
				}
			}
		}
	}
	.search {
		$height: 40px;
		height: $height;
		width: 100%;
		position: relative;
		top: 0;
		left: 0;
		display: block;
		box-sizing: border-box;
	
		&__text {
			// border: 1px solid #ccc;
			border: 1px solid palette('blue', 'link');
			border-radius: 4px;
			height: $height;
			line-height: $height;
			font-size: $fontsize16;
			width: 100%;
			background-color: #ededed;
			box-sizing: border-box;
			padding: 0 35px 0 10px;
			display: block;
			color: palette('blue', 'link');
			@media #{$sp} {
				font-size: 16px;
			}
		}
		&__submit {
			cursor: pointer;
			position: absolute;
			top: 1px;
			right: 1px;
			width: 40px;
			height: 40px;
			border: 0;
			padding: 0;
			margin: 0;
			box-sizing: border-box;
			background: url('../img/icon/search.png') center center / 45px 45px no-repeat transparent;
			border-left: 1px solid palette('blue', 'link');
		}
	}
	&__search {
		//.search_btn {
		//	@include on_opacity;
		//	width: 45px;
		//	height: 45px;
		//	display: flex;
		//	flex-direction: column;
		//	justify-content: center;
		//	align-items: center;
		//	border-radius: 50%;
		//	background: url(/common/img/icon/search.png) no-repeat center center / cover;
		//	transition: background-color .2s $easeOutQuint;
		//	position: relative;
		//	top: 0;
		//	left: 0;
		//	.text {
		//		@include hide-text;
		//	}
		//}
	}
	&._open_search {
		animation-name: header_search_show;
		animation-duration: .3s;
		animation-timing-function: $easeOutQuint;
		animation-direction: normal;
		animation-fill-mode: forwards;
	}
	&._close_search {
		animation-name: header_search_hide;
		animation-duration: .3s;
		animation-timing-function: $easeOutQuint;
		animation-direction: normal;
		animation-fill-mode: forwards;
	}


	&_bottom {
		transition: 0.4s;
		transform: translateY(0);
		z-index: 1;
		position: relative;
		background-color: #a68f6a;
		height: 61px;
		.gnav {
			transition: 0.4s;
			@media screen and (min-width: 1320px) {
				max-width: 1200px;
				margin: 0 auto;
			}
			@media screen and (max-width: 1319px) {
				width: 100%;
			}
			position: relative;
			//&::before {
			//	width: 100vw;
			//	content: "";
			//	display: block;
			//	//border-bottom: 1px solid #d5cec8;
			//	position: absolute;
			//	bottom: -1px;
			//	left: 50%;
			//	transform: translateX(-50%);
			//	transition: 0.4s;
			//}
			&__sub {
				display: none;
				opacity: 0;
				transition: 0.3s;
				background-color: #fff;
				margin: 0 calc(50% - 50vw); 
				width: 100vw;
				&-show {
					display: block;
					width: 100vw;
					height: auto;
					position: absolute;
					left: 0;
					top: 61px;
					border-bottom: 1px solid #b37676;
					animation-name: gnav-sub-show;
					animation-duration: .5s;
					animation-timing-function: $easeOutQuint;
					animation-direction: normal;
					animation-fill-mode: forwards;
				}
			}
			
			&__ul {
				width: 100%;
				display: flex;
				justify-content: center;
				box-sizing: border-box;
				transition: 0.4s;
			}
			&__li {
				//width: 100%;
				min-width: 145px;
				display: flex;
				align-items: center;
				justify-content: center;
				@media #{$lg1260} {
					min-width: auto;
				}
				&:not(:last-of-type) {
					> .gnav__a {
						position: relative;
						&::before {
							content: "";
							display: block;
							width: 1px;
							height: 32px;
							border-right: 1px solid #c7b7a6;
							position: absolute;
							right: 0;
							top: 50%;
							margin-top: -16px;
						}
						
					}
				}
				&:hover {
					.gnav__a {
						position: relative;
						&::after {
							height: 3px;
						}
					}
				}
			}
			&__a {
				width: 100%;
				height: 100%;
				display: block;
				color: #fff;
				font-size: $fontsize17;
				letter-spacing: 0.075em;
				padding: 23px 35px 21px;
				line-height: 1;
				box-sizing: border-box;
				text-align: center;
				@media #{$lg1260} {
					padding: 23px 25px 21px;
					font-size: $fontsize15;
				}
				> .text {
					display: block;
					white-space: nowrap;
				}
				&::after {
					content: "";
					display: block;
					width: 100%;
					height: 0;
					background-color: #c7b7a6;
					position: absolute;
					bottom: 0;
					left: 0;
					transition: 0.2s height;
				}
			}
			&__text {
				width: 100%;
				height: 36px;
				border-right: 1px solid #d5cec8;
				display: flex;
				align-items: center;
				line-height: 1;
				justify-content: center;
				transition: 0.6s;
			}
		}
	}
}


.header{
	.gnav__sub {
		padding-bottom: 30px;
		padding-top: 20px;
		&_ul1 {
			display: flex;
			flex-wrap: wrap;
			width: calc(100% + 20px);
			margin-left: -10px;
			> * {
				width: calc(33.33% - 10px);
				padding: 5px;
			}
		}
		&:not(._department):not(._case) {
			.li1.coming_soon {
				.a1 {
					.gnav_image {
						position: relative;
						&::before {
							@include notoserif;
							content: "Coming soon...";
							font-size: 14px;
							color: #fff;
							display: block;
							width: 100%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							// background: url(/common/img/comming-soon.png) no-repeat center center / cover;
							z-index: 5;
							opacity: 1!important;
							background-color: rgba(204, 204, 204, 0.7);
							box-sizing: border-box;
							padding: 20px;
						}
					}
				}
				
			}
			.a1 {
				display: flex;
				align-items: stretch;
				border: 1px solid #770000;
				
				.gnav_image {
					display: block;
					width: 160px;
					position: relative;
					overflow: hidden;
					img {
						object-fit: cover;
						object-position: center;
						position: absolute;
						width: 100%;
						height: 100%;
						@media all and (-ms-high-contrast: none) {
							/* IE11以上 */
							font-family: 'object-fit: cover;';
						}
					}
				}
				.gnav_text {
					width: calc(100% - 160px);
					min-height: 90px;
					color: #770000;
					font-weight: 500;
					font-size: 17px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 10px;
					box-sizing: border-box;
					position: relative;
					overflow: hidden;
					@media #{$lg1260} {
						font-size: 16px;
					}
					.inner {
						position: relative;
						z-index: 1;
					}
					&::before {
						content: "";
						display: block;
						width: 600px;
						height: 600px;
						background-color: #770000;
						transform: rotate(-45deg);
						position: absolute;
						bottom: -500px;
						right: -500px;
						transition: 0.5s $easeOutCirc;
					}
					
					> * {
						min-height: 0%;
						max-width: 100%;
					}
				}
				&:hover {
					.gnav_text {
						color: #fff;
						&::before {
							bottom: -170px;
							right: -170px;
							transition: 4s $easeOutCirc;
							
						}
						.ext {
							&::after {
								background-image: url(/common/img/icon/ext_white.png);
							}
						}
						.pdf {
							&::after {
								background-image: url(/common/img/icon/pdf_white.png);
							}
						}
					}
				}
			}
		}
		&._department,
		&._case {
			.gnav__sub_ul1 {
				width: calc(100% + 10px);
				margin-left: -5px;
				> * {
					width: calc(33.33% - 10px);
					padding: 5px;
				}
			}
			.a1 {
				display: flex;
				align-items: center;
				border: 1px solid #770000;
				padding: 4px 0;
				position: relative;
				overflow: hidden;
				background-color: #fff;
				transition: 0.8s $easeOutCirc;
				&::before {
					content: "";
					display: block;
					width: 600px;
					height: 600px;
					background-color: #770000;
					transform: rotate(-45deg);
					position: absolute;
					bottom: -500px;
					right: -500px;
					transition: 0.2s $easeOutCirc;
					z-index: -1;
				}
				.gnav_image {
					width: 35px;
					height: 35px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 5px;
					margin-right: 5px;
					padding: 5px;
					transition: 0.1s;
					img {
						width: 100%;
						transition: 0.3s;
					}
				}
				.gnav_text {
					width: calc(100% - 60px);
					color: #770000;
					font-weight: 500;
					font-size: 18px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					padding: 5px;
					box-sizing: border-box;

					@media #{$lg1260} {
						font-size: 16px;
					}
					
					
					> * {
						min-height: 0%;
						max-width: 100%;
					}
				}
				&:hover {
					background-color: #770000;
					&::before {

					}
					.gnav_image {
						img {
							transform: scale(1.1);
						}
					}
					.gnav_text {
						color: #fff;
						.ext {
							&::after {
								background-image: url(/common/img/icon/ext_white.png);
							}
						}
						.pdf {
							&::after {
								background-image: url(/common/img/icon/pdf_white.png);
							}
						}
					}
				}
			}
		}
		&._case {
			.a1 {
				.gnav_image {
					border-radius: 50%;
					background-color: #fff;
				}
			}
		}
		&._department {
			.a1 {
				.gnav_image {
					border-radius: 50%;
					background-color: #770000;
				}
			}
		}
		&_ul1._interview {
			.a1 {
				.gnav_image {
					img {
						position: absolute;
						height: calc(100% + 2px);
						min-height: 155px;
						width: auto;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						//margin-left: 8%;
					}
				}
				.gnav_text {
					.position {
						font-size: 0.8em;
						margin-right: 0.6em;
						display: inline-block;
					}
				}
			}
		}

		&_title {
			color: #563108;
			font-weight: 500;
			font-size: 20px;
			margin-bottom: 5px;
			.text {
				display: block;
				position: relative;
				padding-left: 65px;
				&::before {
					content: "";
					display: block;
					width: 50px;
					height: 28px;
					background-color: #770000;
					position: absolute;
					left: 0;
					top: 3px;
					background-image: url(/common/img/icon/arrow/arrow_white.png);
					background-repeat: no-repeat;
					background-size: 32px 6px;
					background-position: center center;
				}
			}
		}

		.js-accordion2 {
			.js-accordion_toggle,
			.js-toggle {
				display: none!important;
			}
		}
		.sub_menu__ul2 {
			display: none;
		}
	}
}