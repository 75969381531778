@charset "utf-8";


@keyframes header_search_show {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(64px);
	}
}
@keyframes header_search_hide {
	0% {
		transform: translateY(64px);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes gnav-sub-show {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}
@keyframes fade-in-up {
	0% {
		opacity: 0;
		transform: translateY(5px);
	}
	1% {
		opacity: 0;
		transform: translateY(5px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes fade-out {
	0% {
		display: block;
		opacity: 1;
	}
	99% {
		display: block;
		opacity: 0;
	}
	100% {
		display: none;
		opacity: 0;
	}
}


@keyframes _on_opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}


@keyframes icon_fuwafuwa {
	0% {
		transform: translateY(0);
	}
	60% {
		transform: translateY(-5px);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes icon_next_move {
	0% {
		transform: translateX(0);
	}
	60% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes open_sub_menu {
	0% {
		height: 0;
	}
	100% {
		height: auto;
	}
}

@keyframes close_sub_menu {
	0% {
		height: auto;
	}
	100% {
		height: 0;
	}
}



@media #{$pc} {
	.fade-in {
		transition: .6s $easeOutQuad;
		opacity: 1;
		&.init {
			transform: translateY(20px);
			opacity: 0;
		}
	}
	.fade-down {
		transition: .6s $easeOutQuad;
		opacity: 1;
		&.init {
			transform: translateY(-20px);
			opacity: 0;
		}
	}
	.fade-left {
		transition: .6s $easeOutQuad;
		opacity: 1;
		&.init {
			transform: translateX(-20px);
			opacity: 0;
		}
	}
	.fade-right {
		transition: .6s $easeOutQuad;
		opacity: 1;
		&.init {
			transform: translateX(20px);
			opacity: 0;
		}
	}
	.delay_02s {
		transition-delay: 0.2s;
	}
	.delay_04s {
		transition-delay: 0.4s;
	}
	.delay_06s {
		transition-delay: 0.6s;
	}	
}
